<template>
  <div v-if="page">
    <banner :banner="page.banner"/>
    <section v-for="(section, index) in page.sections" :key="section.title" class="section grid-x" :class="{['-s' + ((index%3) + 1)]: true}">
      <div class="cell small-12 medium-6 content" :class="{'medium-order-2 -right': index%2 == 0,  '-left': index%2 != 0}">
        <p class="section-title" v-html="section.title"/>
        <p class="section-text" v-html="section.text"/>
      </div>
      <div class="cell small-12 medium-6" :class="{'medium-order-1': index%2 != 0}">
        <v-image class="banner-image" :image="section.image" :class="{'-align-bottom': index%3 == 0, '-align-top': index%3 == 1, '-align-center': index%3 == 2}"/>
      </div>
    </section>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import Banner from '@/components/our-story/Banner.vue';
  import VImage from '@/components/Image.vue';

  export default {
    metaInfo: {
      title: 'Black Tower',
      titleTemplate: null
    },
    components: {
      Banner,
      VImage
    },
    mounted() {
      this.fetchPage('our-story')
        .then(() => {
        });
    },
    methods: {
      ...mapActions(['fetchPage']),
    },
    computed: {
      page() {
        return this.$store.getters.getPage('our-story');
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .section {
    &.-s1 {
      background-color: #A6AFB5;
    }
    &.-s2 {
      background-color: #FEE9C6;
    }
    &.-s3 {
      background-color: #C4DCC6;
    }
  }
  .content {
    padding: vw-relative(20, 320);
    &.-right {
      @include breakpoint(medium) {
        padding: vw-relative(106) vw-relative(118) vw-relative(106) vw-relative(102);
      }
    }
    &.-left {
      @include breakpoint(medium) {
        padding: vw-relative(106) vw-relative(102) vw-relative(106) vw-relative(72);
      }
    }
  }
  .section-title {
    font: #{vw-relative(18, 320)}/1 CenturyGothicPaneuropean;
    text-transform: uppercase;
    margin-bottom: 0.69em;
    @include breakpoint(medium) {
      font-weight: 300;
      font-size: vw-relative(36);
    }
    @include breakpoint(xlarge) {
      font-size: 36px;
    }
  }
  .section-text {
    font: 300 #{vw-relative(16, 320)}/1.2 CenturyGothicPaneuropean;
    @include breakpoint(medium) {
      font-size: vw-relative(17);
    }
    @include breakpoint(xlarge) {
      font-size: 17px;
    }
  }
  .banner-image {
    width: 100%;
    height: auto;
    padding: vw-relative(20, 320);
    &.-align-bottom {
      @include breakpoint(medium) {
        padding: vw-relative(106) vw-relative(16) 0 vw-relative(118);
      }
    }
    &.-align-top {
      @include breakpoint(medium) {
        padding: 0 vw-relative(118) vw-relative(106) vw-relative(16);
      }
    }
    &.-align-center {
      @include breakpoint(medium) {
        padding: vw-relative(42) vw-relative(16) vw-relative(42) 0;
      }
    }
  }
</style>
